* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 1rem;
  font-family: Courier, 'Courier New', monospace;
  color: #222;
  display: grid;
  place-content: center;
  text-align: center;
  line-height: 1.5;
  object-fit: contain;
}

nav {
  font-size: 1.1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 1.75rem;
  padding-bottom: .75em;
  background-color: #fff;
  opacity: 90%;
  color: #111;
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  z-index: 100;
}

.site-title {
  margin-top: -.85rem;
  font-size: 2.25rem;
  font-weight: bold;
}

.nav-button {
  margin-right: 1.75rem;
}

nav a {
  color: inherit;
  text-decoration: none;
}

nav .active {
  color: #9313b0;
}

nav a:hover {
  color: #bbb
}

.name {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 6.66rem;
  position: relative;

}

h1::before,
h1::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h1::before {
  background: white;
  animation: typewriter 2.5s steps(14) 250ms forwards;
}

h1::after {
  width: 0.1em;
  background: #9313b0;
  animation: 
    typewriter 2.5s steps(14) 250ms forwards,
    blink 750ms steps(14) infinite;
}

h2 {
  font-size: 1.5rem;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeUp .8s 2.75s forwards;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

p {
  font-size: 1.05rem;
  text-align: left;
  color: rgb(90, 90, 90);
}

h3 {
  font-size: 1.275rem;
  font-weight: 600;
}

h4 {
  font-size: 1.35rem;
  font-weight: 800;
}

.highlight {
  color: #9313b0;
}

.home {
  margin-top: 10rem;
  text-align: center;
}

.bio {
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  text-align: left;
  max-width: 755px;
  animation: fadeIn .75s;
  padding: 1rem;
}

.me {
  float: left;
  max-width: 40%;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: .75rem;
  opacity: 0;
  transform: translateX(-3rem);
  animation: fadeRight 1s forwards;
}

.cv {
  margin-top: 1.625rem;
  animation: fadeIn .75s;
}

.cv-image {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  filter: grayscale(100%);
}

.contact-text {
  margin-top: 15rem;
  animation: fadeIn .75s;
}

.icons {
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeUp 1s forwards;
}

.logo {
  margin-top: 7.5rem;
  width: 60px;
  padding: .5rem;
  transition: 200ms transform ease-in-out;
}

.logo2 {
  margin-top: 7.5rem;
  width: 70px;
  padding: .5rem;
  transition: 200ms transform ease-in-out;
}

.logo:hover, .logo2:hover {
  transform: scale(1.175);
}

.container {
  margin-top: 8.25rem;
  margin-bottom: 4.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  gap: 2rem;
  align-items: center;
  animation: fadeIn .75s;
}

.text-container {
  margin-left: .5rem;
  margin-right: .5rem;
}

.card {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  text-align: left;
  background: white;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2);
}

.card-image {
  overflow: hidden;
}

.card-image > img {
  display: block;
  float: right;
  width: 100%;
  object-fit: cover;
  transition: 200ms transform ease-in-out;
  margin-bottom: 1rem;
}

.card:hover > .card-image > img {
  transform: scale(1.025);
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.card-details {
  font-size: .9rem;
  margin-bottom: .5rem;
  color: rgb(90, 90, 90);
}

.button {
  width: 21%;
  font-family: inherit;
  padding: .3rem 1rem;
  background-color: white;
  margin: .5rem;
}

.button2 {
  width: 10.5rem;
  font-family: inherit;
  padding: .3rem 2rem;
  background-color: white;
  margin: .5rem;
}

.pdf-button {
  margin-top: 7rem;
  display: block;
  animation: fadeIn .75s;
}

.button:hover, .button:focus, .button2:hover, .button2:focus {
  background: #ddd;
  color: #111;
  cursor: pointer;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 900px) {
  body {
    display: block;
  }.site-title {
    margin-top: 0rem;
  }
  .home {
    margin-top: 11rem;
  }
  .bio {
    margin-top: 4rem;
    max-width: 100%;
  }
  p {
    font-size: 1rem;
  }
  .me {
    max-width: 55%;
  }
  .container {
    margin-top: 6rem;
  }
  .card-title {
    font-size: 1rem;
  }
  .card-details {
    font-size: 1rem;
  }
  .button {
    border: 1.375px solid #303030;
    width: 9.5rem;
    font-family: inherit;
    padding: .5rem 1rem;
    color: #222;
  }
  .button2 {
    border: 1.375px solid #303030;
    font-size: .6rem;
    width: 9rem;
    font-family: inherit;
    padding: .4rem 2rem;
    color: #222;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.1rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  .cv-image {
    width: 100%;
  }
  .logo, .logo2 {
    margin-top: 5rem;
  }
  .contact-text {
    margin-top: 10rem;
  }
  .hamburger {
    display: block;
    z-index: 4;
  }
  nav {
    padding: 1.5rem;
    position: absolute;
    opacity: 100;
  }
  .nav-links {
    top: 0;
    left: -200%;
    position: fixed;
    background-color: rgb(33, 33, 33);
    width: 230px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #eeeeee;
    gap: 2rem;
    z-index: 100;
    transition: 0.5s ease-in-out;
    transform: translateX(100%);
  }
  .nav-links-active {
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgb(33, 33, 33);
    width: 230px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #eeeeee;
    gap: 2rem;
    z-index: 100;
    transition: 0.5s ease-in-out;
    transform: translateX(0);
  }
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
  button {
    border: 1.5px solid #303030;
    width: 45%;
    font-family: inherit;
    padding: .6rem 2rem;
    color: #222;
  }
}